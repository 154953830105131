import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Formik } from "formik";

// image
import logo from "images/logo-full.png";
import { useDispatch } from "react-redux";
import { registerUser } from "store/slices/AppReducer";
import { RegisterSchema } from "utils/helpers/RegisterValidation";
import { ToastMsg } from "utils";

const registerInitialValues = {
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  mobile: "",
  address1: "",
  address2: "",
  city: "",
  state: "",
  zipcode: ""
};

function Register() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const submitRegister = async(values) => {
    const { 
      firstName, lastName, email, password, mobile,
      address1, address2, city, state, zipcode 
    } = values;
    try {
      dispatch(registerUser({
        firstName,
        lastName,
        email,
        password,
        mobile,
        address1,
        address2,
        city,
        state,
        zipcode
      }));
      ToastMsg.showSuccess("User Register successfully!!!");
      navigate('/login')
    } catch (error) {
      ToastMsg.showError(error || "Error while Registering");
      console.error(error);
    }
  }

  return (
    <div className="authincation h-100 p-meddle">
      <div className="container h-100">
        <div className="row justify-content-center h-100 align-items-center">
          <div className="col-md-6">
            <div className="authincation-content">
              <div className="row no-gutters">
                <div className="col-xl-12">
                  <div className="auth-form">
                    <div className="text-center mb-3">
                      <Link to="/login">
                        <img src={logo} alt="" />
                      </Link>
                    </div>
                    <h4 className="text-center mb-4 ">Sign up your account</h4>
                    <Formik
                      initialValues={registerInitialValues}
                      onSubmit={submitRegister}
                      validationSchema={RegisterSchema}
                    >
                      {({ handleSubmit, handleChange, errors }) => (
                        <form onSubmit={handleSubmit}>
                          <div className="form-group mb-3">
                            <label className="mb-1 ">
                              <strong>First Name</strong>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Adam"
                              onChange={handleChange}
                              name="firstName"
                            />
														{errors.firstName && <div className="text-danger fs-12">{errors.firstName}</div>}
                          </div>
                          <div className="form-group mb-3">
                            <label className="mb-1 ">
                              <strong>Last Name</strong>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Scott"
                              onChange={handleChange}
                              name="lastName"
                            />
														{errors.lastName && <div className="text-danger fs-12">{errors.lastName}</div>}
                          </div>
                          <div className="form-group mb-3">
                            <label className="mb-1 ">
                              <strong>Email</strong>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="adam@scott.com"
                              onChange={handleChange}
                              name="email"
                            />
														{errors.email && <div className="text-danger fs-12">{errors.email}</div>}
                          </div>
                          <div className="form-group mb-3">
                            <label className="mb-1 ">
                              <strong>Password</strong>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="It's Secret"
                              onChange={handleChange}
                              name="password"
                            />
														{errors.password && <div className="text-danger fs-12">{errors.password}</div>}
                          </div>
                          <div className="form-group mb-3">
                            <label className="mb-1 ">
                              <strong>Mobile</strong>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="466-8444-888"
                              onChange={handleChange}
                              name="mobile"
                            />
														{errors.mobile && <div className="text-danger fs-12">{errors.mobile}</div>}
                          </div>
                          <div className="form-group mb-3">
                            <label className="mb-1 ">
                              <strong>Permanent Address</strong>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="L.A."
                              onChange={handleChange}
                              name="address1"
                            />
														{errors.address1 && <div className="text-danger fs-12">{errors.address1}</div>}
                          </div>
                          <div className="form-group mb-3">
                            <label className="mb-1 ">
                              <strong>Current Address</strong>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="N.Y."
                              onChange={handleChange}
                              name="address2"
                            />
														{errors.address2 && <div className="text-danger fs-12">{errors.address2}</div>}
                          </div>
                          <div className="form-group mb-3">
                            <label className="mb-1 ">
                              <strong>City</strong>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Texas"
                              onChange={handleChange}
                              name="city"
                            />
														{errors.city && <div className="text-danger fs-12">{errors.city}</div>}
                          </div>
                          <div className="form-group mb-3">
                            <label className="mb-1 ">
                              <strong>State</strong>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Dallas"
                              onChange={handleChange}
                              name="state"
                            />
														{errors.state && <div className="text-danger fs-12">{errors.state}</div>}
                          </div>
                          <div className="form-group mb-3">
                            <label className="mb-1 ">
                              <strong>Zip Code</strong>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="3900042"
                              onChange={handleChange}
                              name="zipcode"
                            />
														{errors.zipcode && <div className="text-danger fs-12">{errors.zipcode}</div>}
                          </div>
                          <div className="text-center mt-4">
                            <button
                              type="submit"
                              className="btn btn-primary btn-block"
                            >
                              Sign me up
                            </button>
                          </div>
                        </form>
                      )}
                    </Formik>
                    <div className="new-account mt-3">
                      <p className="">
                        Already have an account?{" "}
                        <Link className="text-primary" to="/login">
                          Sign in
                        </Link>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;

