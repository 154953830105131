import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import agent from "API/agent";
import { getToken, removeToken, setToken } from "utils";

const initialState = {
  loggedInUserInfo: {},
  userToken: getToken() || null
};

export const loginUser = createAsyncThunk('login/callLogin', async({userData, navigateTo}) => {
  const user = await agent.Account.signIn(userData);
  return { user, navigateTo };
})

const commonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    registerUser: async(state, action) => {
      try {
        await agent.Account.signUp(action.payload);
        // setToken(user.token);
        // action.payload.navigated('/users')
      } catch(err) {
        console.log("Register Error", err);
      }
    },
    logoutUser: (state) => {
      state.userToken = null;
      removeToken();
    }
  },
  extraReducers(builder) {
    builder.addCase(loginUser.fulfilled, (state, action) => {
      const { user, token } = action.payload.user;
      setToken(token);
      state.loggedInUserInfo = user;
      action.payload.navigateTo('/users');
    })
  }
});

export const { registerUser, logoutUser } = commonSlice.actions;

export default commonSlice.reducer;
