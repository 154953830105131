import React from 'react';
import { Link, useNavigate } from 'react-router-dom'
import { Formik } from 'formik';
import { useDispatch } from "react-redux";

// image
import logo from "images/logo-full.png";
import loginbg from "images/pic1.png";
import { loginUser } from 'store/slices/AppReducer';
import { LoginSchema } from 'utils/helpers/LoginValidation';
import { ToastMsg } from 'utils';

const loginInitialValues = {
	email: "",
	password: "",
};

function Login () {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const submitLogin = async(values) => {
		const { email, password } = values;
		try {
			await dispatch(loginUser({userData:{email, password}, navigateTo: navigate}));
			ToastMsg.showSuccess("Login successfully!!!")
		} catch (error) {
			ToastMsg.showError(error || "Error While Login");
			console.log("Login Error", error);
		}
		;
	}

  return (
		<div className="authincation d-flex flex-column flex-lg-row flex-column-fluid">
			<div className="login-aside text-center  d-flex flex-column flex-row-auto">
				<div className="d-flex flex-column-auto flex-column pt-lg-40 pt-15">
					<div className="text-center mb-4 pt-5">
						<img src={logo} alt="" />
					</div>
					<h3 className="mb-2">Welcome back!</h3>
					<p>User Experience & Interface Design <br />Strategy SaaS Solutions</p>
				</div>
				<div className="aside-image" style={{backgroundImage:"url(" + loginbg + ")"}}></div>
			</div>
			<div className="container flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-7 mx-auto">
				<div className="d-flex justify-content-center h-100 align-items-center">
					<div className="authincation-content style-2">
						<div className="row no-gutters">
							<div className="col-xl-12 tab-content">
								<div id="sign-in" className="auth-form   form-validation">
									<Formik
										initialValues={loginInitialValues}
										onSubmit={submitLogin}
										validationSchema={LoginSchema}
									>
										{({ handleChange, handleSubmit, errors }) => (
											<form className="form-validate" onSubmit={handleSubmit}>
												<h3 className="text-center mb-4 text-black">Sign in your account</h3>
												<div className="form-group mb-3">
													<label className="mb-1"  htmlFor="val-email"><strong>Email</strong></label>
													<div>
														<input type="email" className="form-control"
															onChange={handleChange}
															placeholder="Type Your Email Address"
															name='email'
														/>
														{errors.email && <div className="text-danger fs-12">{errors.email}</div>}
													</div>
												</div>
												<div className="form-group mb-3">
													<label className="mb-1"  htmlFor="val-email"><strong>Password</strong></label>
													<div>
														<input type="password" 		className="form-control"
															onChange={handleChange}
															placeholder="Password"
															name='password'
														/>
														{errors.password && <div className="text-danger fs-12">{errors.password}</div>}
													</div>
												</div>
												<div className="form-row d-flex justify-content-between mt-4 mb-2">
													<div className="form-group mb-3">
														<div className="custom-control custom-checkbox ml-1">
															<input type="checkbox" className="form-check-input" id="basic_checkbox_1" />
															<label className="form-check-label" htmlFor="basic_checkbox_1">Remember my preference</label>
														</div>
													</div>
												</div>
												<div className="text-center form-group mb-3">
													<button type="submit" className="btn btn-primary btn-block">
														Sign In
													</button>
												</div>
											</form>
										)}
									</Formik>
									<div className="new-account mt-3">
										<p>Don't have an account? <Link className="text-primary" to="/sign-up">Sign up</Link></p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>	
		
  );
};

export default Login;
