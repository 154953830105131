import React from "react";
import { ToastContainer } from "react-toastify";
import { Router } from "routes";
import "styles/css/style.css";

function App() {

  return (
    <div className="min-vh-100">
      <ToastContainer />
      <Router />
    </div>
  );
}

export default App;
