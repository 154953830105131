import * as Yup from "yup";

export const RegisterSchema = Yup.object().shape({
  firstName: Yup.string().required("First Name is required"),
  lastName: Yup.string().required("Last Name is required"),
  email: Yup.string().email("Email format is not proper").required("Email is required"),
  password: Yup.string().required("Password is required"),
  mobile: Yup.string().required("Mobile number is required"),
  address1: Yup.string().required("Address1 is required"),
  address2: Yup.string().required("Address2 is required"),
  city: Yup.string().required("City name is required"),
  state: Yup.string().required("State name is required"),
  zipcode: Yup.string().required("Zipcode is required"),
});
