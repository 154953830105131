import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Set Token to Local Storage
export const setToken = (token) => localStorage.setItem('token', token);

// Get Token from Local Storage
export const getToken = () => localStorage.getItem('token');

// Remove Token from Local Storage
export const removeToken = () => localStorage.removeItem('token');

// Common Toast Message
export const ToastMsg = {
  showError: (message) => {
    toast.error(message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  },
  showSuccess: (message) => {
    toast.success(message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  },
  showInfo: (message) => {
    toast.info(message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  },
  showWarning: (message) => {
    toast.warning(message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  },
};
