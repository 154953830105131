import { configureStore } from "@reduxjs/toolkit"
import commonReducer from "./slices/AppReducer";

export const store = configureStore({
  reducer: {
    commonReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    })
});
