import React from "react";

import AppLayout from "layouts/AppLayout";
import { Auth } from "hoc";
import { BootstrapTable } from "components";
import UserList from "pages/UserList";

const ModuleRoutes = () => ([
  {
    path: "users",
    component: UserList,
    hoc: Auth
  },
  { 
    path: "table-bootstrap-basic", 
    component: BootstrapTable,
    hoc: Auth
  },
  {
    path: "",
    component: UserList,
    hoc: Auth
  }
]);

const MainRoutes = () => {
  const Route = ModuleRoutes().map(route => {
    const WrappedComponent = route.hoc(route.component);
    return { path: route.path, element: <WrappedComponent /> };
  });

  return {
    path: "/",
    element: <AppLayout />,
    children: [
      ...Route
    ]
  }
};

export default MainRoutes;
