import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";

import { getToken } from "utils";

const Redirect = ({ ComposedComponent }) => {
  const token = getToken();
  const [content, setContent] = useState(null);

  useEffect(() => {
    if (token) {
      setContent(<Navigate to={'/users'} />)
    } else {
      setContent(<ComposedComponent />)
    }
  }, [token, ComposedComponent])
  
  return content;
};

export default Redirect;
