import React from "react";
import { Login, Register } from "pages";
import { Redirect } from "hoc";
import AuthLayouts from "layouts/AuthLayout";

const LoginRoutes = {
  path: "/",
  element: <AuthLayouts />,
  children: [
    {path: "sign-up", element: <Redirect ComposedComponent={Register} />},
    {path: "login", element: <Redirect ComposedComponent={Login} />}
  ]
};

export default LoginRoutes;
