import React from "react";
import { getToken } from "utils";
import { Navigate } from "react-router-dom";

const Auth = (ComposedComponent) => {
  const AuthWrapper = () => {
    const token = getToken();
  
    // will write the condition if we have to pass token for API call
    let content;
  
    if (token) {
      content = (
      <ComposedComponent />
      )
    } else {
      content = (<Navigate to ='/login' />)
    }
    return content;
  };
  return AuthWrapper;

};
export default Auth;
