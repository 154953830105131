import { ThemeContext } from "context/ThemeContext";
import React, { useContext } from "react";
import { Outlet } from "react-router-dom";
import Nav from "./nav";

const AppLayout = () => {
  const { menuToggle, sidebariconHover } = useContext(ThemeContext);
  return(
    <div id="main-wrapper" className={`show ${sidebariconHover ? "iconhover-toggle": ""} ${ menuToggle ? "menu-toggle" : ""}`}>  
    <Nav />
    <div className="content-body" style={{ minHeight: window.screen.height - 45 }}>
        <div className="container-fluid">
          <Outlet />                
        </div>
    </div>
    {/* <Footer /> */}
  </div>
)};

export default AppLayout;
