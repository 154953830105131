import axios from "axios";
import { EndPoints } from "utils/endpoint";

axios.defaults.baseURL = EndPoints.BaseURL;

// Note: Will use in future when we call API's
// axios.interceptors.request.use((config) => {
//     delete axios.defaults.headers.common["Authorization"];
//     const token = store.commonStore.token;
//     if (token) config.headers!.Authorization = `Bearer ${token}`;
//     return config;
// });

const request = {
  get: (url) => axios.get(url).then((responseBody) => responseBody.data),
  post: (url, body) => axios.post(url, body).then((responseBody) => responseBody.data),
  put: (url, body) => axios.put(url, body).then((responseBody) => responseBody.data),
  del: (url) => axios.delete(url).then((responseBody) => responseBody.data),
};

const Account = {
  signUp: (user) => request.post(EndPoints.Account.SignUp, user),
  signIn: (user) => request.post(EndPoints.Account.SignIn, user),
};

const agent = {
  Account,
};

export default agent;
